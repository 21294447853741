<template>

    <div class="dashboard">


        <!--#region MODAL ATENDIMENTOS PENDENTES-->
        <b-modal ref="modal_all_atendimentos"
                 size="xl"
                 scrollable
                 id="modal_all_atendimentos"
                 hide-footer title="Lista de atendimentos pendentes">
            <div class="d-block text-center">
                <div id="tabela_atendimento_pendentes">
                    <b-row id="cabecalho_tabela_pendentes">
                        <b-col>Protocolo</b-col>
                        <b-col>Atendente</b-col>
                        <b-col>Cliente</b-col>
                        <b-col>Ações</b-col>
                    </b-row>
                    <b-row v-for="(item, index) in listaAtendimento" :class="computedClass(index)">

                        <b-col><span class="text_at_pendente">{{item.Protocolo}}</span></b-col>
                        <b-col><span class="text_at_pendente">{{item.Atendente}}</span></b-col>
                        <b-col><span class="text_at_pendente">{{item.Cliente}}</span></b-col>
                        <b-col class="caixa_acoes"><i id="botaoInfoIcon" class="fas fa-info-circle fa-fw conversa_funil" @click="abrirConversa(item)"></i></b-col>
                    </b-row>
                </div>
            </div>
        </b-modal>
        <!--#endregion-->
          
        <Breadcrumb titulo="Dashboard"
                    :items="item" />

        <div class="grid-container">

            <div class="cards">
                <div class="dashboard-cards col"
                     style="padding:0"
                     v-for="(cartao,index) in  dashboardCards"
                     :key="index">
                    <cartao-dashboard :titulo="cartao.titulo"
                                      :subtitulo="cartao.subtitulo"
                                      :class=" cartao.permiteClick ? 'cursor-pointer' : ''"
                                      :permiteClick="cartao.permiteClick"
                                      :link="cartao.link"
                                      :origem="cartao.origem"
                                      @modalAllAtendimento="abrir_modalAllAtendimento" />
                </div>
            </div>


            <!--#region GRÁFICO HORIZONTAL (*NÃO REMOVER*) -->
            <!--<div class="horizontal" style="display: none;">
            <span style="display: block; left: 46%; color: #A5A5A5;"> Atendimento x Canal x Usuario.</span>
            <span style="font-size: 15px; float:left; cursor:pointer; " @click="start"> {{visible_horizontal ?  'Ocultar ' : 'Mostrar '}}Grafico Horizontal </span>

            <b-icon icon="arrow-clockwise" animation="spin" font-scale="4" id="carregamento_animado" style="width: 46px; color: #19b1bd; margin: 0px auto; display:none "></b-icon>
            <div id="grafico_horizontal" style="display:none">
                <grafico-horizontal v-if="horizontalLoad"
                                    :usuarios="usuarios_horizontal"
                                    :infos="infos_horizontal"
                                    ref="graficoHorizontal" />
            </div>
        </div>-->
            <!--#endregion -->


            <div class="linha">
                <h3 class="dashboard-titulo">Atendimentos x Horas</h3>

                <b-overlay :show="!areaLoad"
                           :class="!areaLoad? 'dashboard-overlay' : '' ">

                    <grafico-area v-if="areaLoad"
                                  :data="dados"
                                  :usuarioWW="usuario.UserWhatsweb" />
                </b-overlay>
                <span class="dashboard-subtitulo">
                    Distribuição por hora da quantidade total de atendimentos da empresa.
                </span>
            </div>
            <div class="coluna">
                <h3 class="dashboard-titulo">Atendimentos x Mês</h3>
                <b-overlay :show="!colunaLoad"
                           :class="!areaLoad? 'dashboard-overlay' : '' ">
                    <grafico-coluna v-if="colunaLoad"
                                    :labelsDataHorizontal="mes"
                                    :labelsDataVertical="numero" />
                </b-overlay>
                <span class="dashboard-subtitulo"> Quantidade total de atendimentos distribuidos por mês. </span>
            </div>
            <div class="rosca">
                <h3 class="dashboard-titulo">Atendimento x Mês</h3>
                <b-overlay :show="!roscaLoad" :class="!areaLoad? 'dashboard-overlay' : '' ">
                    <grafico-rosca v-if="roscaLoad"
                                   :label="roscaLabel"
                                   :dados="roscaDados" />
                </b-overlay>
                <span class="dashboard-subtitulo"> Origem dos atendimentos total do mês corrente. </span>
            </div>
            <div class="pizza">

                <h3 class="dashboard-titulo">Atendimento x Dia</h3>
                <b-overlay :show="!pizzaLoad"
                           :class="!areaLoad? 'dashboard-overlay' : '' ">
                    <grafico-pizza v-if="pizzaLoad"
                                   :data="pizzaDados" />
                </b-overlay>
                <span class="dashboard-subtitulo"> Origem dos atendimentos do dia corrente. </span>
            </div>
        </div>

        <modalGraficosAtendimentos ref="modal_atendimentos_pendentes"
                                   id="modal_atendimentos_pendentes"/>
        <ModalConversaPadrao ref="modal_conversa_padrao"
            :dadosConversa="dadosConversa"
            :tabulacaoId="TabulacaoId"
            style="display:contents" />
    </div>
</template>


<script>
    import usuarioLogado from '@/assets/js/providers/usuario-logado';
    import modalGraficosAtendimentos from '@/components/dashboard/modalGraficosAtendimentos.vue'
    import SignalRIndicadores from '@/assets/js/hubs/RegistrosHub'
    import axios from 'axios'
    import { mapGetters, mapActions } from 'vuex'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    //import GraficoLinha from '../components/graficos/GraficoLinha.vue'
    import GraficoColuna from '../components/graficos/GraficoColuna.vue'
    import GraficoHorizontal from '../components/graficos/GraficoHorizontal.vue'
    import GraficoRosca from '../components/graficos/GraficoRosca.vue'
    import GraficoPizza from '../components/graficos/GraficoPizza.vue'
    import GraficoArea from '../components/graficos/GraficoArea.vue'
    import CartaoDashboard from '../components/cartao/CartaoDashboard.vue'   
    import ModalConversaPadrao from '@/components/consultas/ModalCoversaPadrao.vue'
    const URL_API = '/api'
    export default {
        name: 'Dashboard',

        components: {
            modalGraficosAtendimentos,
            Breadcrumb,
            GraficoColuna,
            GraficoRosca,
            GraficoPizza,
            GraficoArea,
            CartaoDashboard,
            GraficoHorizontal,
            ModalConversaPadrao
        },
        created() {
			this.buscaEmpresas();
			SignalRIndicadores.start();
			SignalRIndicadores.onIndicadores(this.atualizaIndicadores);
        },

		destroyed() {
			SignalRIndicadores.stop();
		},

        computed: {
            ...mapGetters({
                dadosUsuarioAutenticado: 'authentication/authDadosUsuario',
               
                loading: 'loadingGlobal',
                listaEmpresas: 'empresasLicencas/listaEmpresas',
            }),
            usuario: function () {
                return usuarioLogado;
            },
            empresa: function () {
                if (this.listaEmpresas) {
                    var filtroMultEmpresas = this.listaEmpresas.filter(emp => emp.MultEmpresas == true);
                    return filtroMultEmpresas.map(m => { return { Nome: m.Nome, Id: m.Id } })
                }
                return {};
            },

        },
        data: function () {
            return {

                listaAtendimento: [],
                colunaLoad: false,
                horizontalLoad: false,
                heruku: true,
                areaLoad: false,
                roscaLoad: false,
                dadosConversa: {},
                TabulacaoId: '',
                roscaAtendimentosLoad: false,
                pizzaLoad: false,
                visible_horizontal: true,
                usuarios_horizontal: [],
                infos_horizontal: [],

                dashboardCards: [
                    {
                        titulo: null,
                        subtitulo: 'Atendimentos pendentes',
                        permiteClick: true,
                        origem: "Pendentes",
                        link: true

                    },
                    {
                        titulo: null,
                        subtitulo: 'Total de atendimento do dia',
                        permiteClick: false,
                        origem: "Dia",
                        link: false
                    },
                    {
                        titulo: null,
                        subtitulo: 'TMA',
                        permiteClick: false,
                        origem: "Maior1",
                        link: false
                    },
                    {
                        titulo: null,
                        subtitulo: 'TME',
                        permiteClick: false,
                        origem: "Maior2",
                        link: false
                    },
                    {
                        titulo: null,
                        subtitulo: 'Agendamentos Pendente',
                        permiteClick: false,
                        origem: "Agendamento",
                        link: false
                    },
                    {
                        titulo: null,
                        subtitulo: 'Atendimento Pendentes/Ativos',
                        permiteClick: true,
                        origem: 'PendentesAtivosTotal',
                        link: false
                    }
                ],

                item: [{
                    id: '2',
                    classe: 'fa fa-area-chart',
                    texto: 'Dashboard',
                    link: '/'
                }],
            }
        },

        mounted() {
            this.atendimentosHoras();
            this.atendimentoTotalMes();
            this.atendimentoPorMes();
            this.atendimentoDia();
            this.atendimentoIndicadores();
            //this.horizontalRendimentos();

        },
        watch: {
            horizontalLoad() {
                if (this.visible_horizontal) {
                    $("#grafico_horizontal").show()
                    $("#carregamento_animado").hide()
                    setTimeout(function () { this.$refs.graficoHorizontal.start(); }, 2000);

                }


            }
        },
        methods: {
            ...mapActions({
                selecionaAtendimentoConsulta: 'atendimento/selecionaAtendimentoConsulta',
                buscaEmpresas: 'empresasLicencas/buscaEmpresas',

            }),
            start: function () {
                this.visible_horizontal = this.visible_horizontal ? false : true;
                if (this.visible_horizontal) {
                    if (this.horizontalLoad) {
                        $("#grafico_horizontal").show()
                        $("#carregamento_animado").hide()
                        this.$refs.graficoHorizontal.start();
                    } else {
                        $("#carregamento_animado").show()
                        $("#carregamento_animado").css({ "display": "block" });
                    }
                } else {
                    $("#grafico_horizontal").hide()
                    $("#carregamento_animado").hide()
                }
            },

            computedClass(index) {
                return "linha_" + Math.ceil((index + 1) % 2);
            },

            buscarAtedimento(valor) {
                this.$emit('atendimento-selecionado', valor)
                this.selecionaAtendimentoConsulta(valor)
                this.$router.push('/atendimento')

            },

            async abrir_modalAllAtendimento(click, origem) {
                if (click) {
                    if (origem == 'Pendentes') {
                        this.$refs['modal_all_atendimentos'].show();
                        const { data } = await axios.get(`${URL_API}/home/GetListaAtendimentos`)
                        this.listaAtendimento = data;
                    }

                    if (origem == 'PendentesAtivosTotal') {
                        this.$refs['modal_atendimentos_pendentes'].iniciarModal();
                    }

                }
            },
            abrirConversa: function (dadosAtendimento) {
                this.dadosConversa = dadosAtendimento.atendimento;
                const valor = { Id: dadosAtendimento.Id, Protocolo: dadosAtendimento.Protocolo, ClienteId: dadosAtendimento.ClienteId, UsuarioLogado: this.usuario, Atendimento: dadosAtendimento }
                this.dadosConversa = valor;
                this.$refs.modal_conversa_padrao.show();
            },
            async atendimentosHoras() {
                try {
                    const { data } = await axios.get(`${URL_API}/home/GetAtendimentosDia`)
                    this.dados = data.dado;

                    this.areaLoad = true
                } catch (e) {
                    console.error(e)
                }
            },

            async atendimentoTotalMes() {
                try {
                    let meses = [
                        "Janeiro",
                        "Fevereiro",
                        "Março",
                        "Abril",
                        "Maio",
                        "Junho",
                        "Julho",
                        "Agosto",
                        "Setembro",
                        "Outubro",
                        "Novembro",
                        "Dezembro"
                    ];
                    const { data } = await axios.get(`${URL_API}/home/GetAtendimentosMes`)
                    this.numero = data.map(list => {
                        return list.Numero
                    });
                    this.mes = data.map(list => {
                        return meses[list.AtendimentoMes - 1];
                    });
                    this.colunaLoad = true;
                } catch (e) {
                    console.error(e)
                }
            },

            //#region GRAFICO HORIZONTAL
            //async horizontalRendimentos() {
            //    /*  try {*/
            //    this.visible_horizontal = false;
            //    let colors = ["#d1d325", "#25d366", "#db4437", "#c4c3f9", "#4267b2", "#a235c1", "#db8437", "#f7d7d7", "#2a9dd6", "#25d366", "#c4c3f9", "#c13584", "#25d366", "#d2fbf4", "#f7d7d7", "#fafbd2", "#e9f9c3", "#9cbeda", "#a4e6da", "#d1d325", "#25d366", "#db4437", "#c4c3f9", "#4267b2", "#a235c1", "#db8437", "#f7d7d7", "#2a9dd6"];
            //    let { data } = await axios.get(`api/relatorios/RelatorioRendimento`)
            //    this.relatorioConsulta = data
            //    let as = this.relatorioConsulta.splice(-1);
            //    this.relatorioConsulta.splice(-1);
            //    //Label Cabecalho(Usuarios)
            //    for (let i in this.relatorioConsulta) {
            //        this.usuarios_horizontal.push(this.relatorioConsulta[i].Usuario)
            //    }
            //    var result = as[0].Grafico.reduce(function (r, a) {
            //        r[a.Canal] = r[a.Canal] || [];
            //        r[a.Canal].push(a.Qnt);
            //        return r;
            //    }, Object.create(null));
            //    let seg = [];
            //    let c = 0;
            //    for (var i in result) {
            //        seg.push({
            //            "label": Object.keys(result)[c],
            //            "data": result[i],
            //            "backgroundColor": colors[c]
            //        })
            //        c++;
            //    }
            //    //DataSets(informações)
            //    this.infos_horizontal = seg
            //    this.horizontalLoad = true;
            //    //} catch (e) {
            //    //    console.error(e)
            //    //}
            //},
            //#endregion

            async atendimentoPorMes() {
                try {
                    const { data } = await axios.get(`${URL_API}/home/GetAtendimentosTotalMes`)
                    this.roscaDados = data.map(list => {
                        return list.Atendimentos
                    });
                    this.roscaLabel = data.map(list => {
                        return list.AtendimentoOrigem;
                    });

                    this.roscaLoad = true;
                } catch (e) {
                    console.error(e)
                }
            },

            async atendimentoDia() {
                try {
                    const { data } = await axios.get(`${URL_API}/home/GetAtendimentosTotalDia`)

                    this.pizzaDados = data;
                    this.pizzaLoad = true;
                } catch (e) {
                    console.error(e)
                }
            },

            async atendimentoIndicadores() {
                try {
                    const { data } = await axios.get(`${URL_API}/relatorios/GetIndicadores`)
                    this.dashboardCards[0].titulo = data.Pendente;
                    this.dashboardCards[1].titulo = data.TotalDeAtendimentos;
                    this.dashboardCards[2].titulo = data.TMA;
                    this.dashboardCards[3].titulo = data.TME;
                    this.dashboardCards[0].permiteClick = data.Perfil;
                    this.dashboardCards[4].titulo = data.AgendamentosPendentes;
                    this.dashboardCards[5].titulo = data.PendentesTotal
                } catch (e) {
                    console.error(e)
                }
            },
           
            atualizaIndicadores(indicadores) {
                this.dashboardCards[0].titulo = indicadores.pendente;
                this.dashboardCards[1].titulo = indicadores.totalDeAtendimentos;
                this.dashboardCards[2].titulo = indicadores.tma;
                this.dashboardCards[3].titulo = indicadores.tme;
                this.dashboardCards[0].permiteClick = indicadores.perfil;
                this.dashboardCards[4].titulo = indicadores.agendamentosPendentes;
                this.dashboardCards[5].titulo = indicadores.pendentesTotal;
            }
        }
    }
</script>


<style scoped>
    /* Configurando layout geral */
    .dashboard {
        padding-bottom: 1%;
    }

    .grid-container {
        display: grid;
        padding: 2%;
        grid-template-areas:
            'cards cards '
            'horizontal horizontal '
            'linha rosca'
            'coluna pizza';
        grid-template-columns: 60% 40%;
        text-align: center;
    }

    /* settando as classes do grid template */
    .linha {
        border-radius: 10px;
        grid-area: linha;
        margin: 1% 1% 1% 0%;
        background-color: #f5f5f5;
        box-shadow: 5px 5px 5px #ccc;
    }

    .rosca {
        border-radius: 10px;
        grid-area: rosca;
        margin: 1% 0% 1.5% 0%;
        background-color: #f5f5f5;
        box-shadow: 5px 5px 5px #ccc;
    }

    .coluna {
        border-radius: 10px;
        grid-area: coluna;
        margin: 1% 1% 1% 0%;
        background-color: #f5f5f5;
        box-shadow: 5px 5px 5px #ccc;
    }

    .horizontal {
        grid-area: horizontal;
        margin: 1% 0% 1% 0%;
        background-color: #f5f5f5;
        box-shadow: 5px 5px 5px #ccc;
    }

    .pizza {
        border-radius: 10px;
        grid-area: pizza;
        margin: 1.5% 0% 1% 0%;
        background-color: #f5f5f5;
        box-shadow: 5px 5px 5px #ccc;
    }

    .cards {
        
        border-radius: 10px;
        /* aqui vc controla o layot dos cards de acordo com a quantidade de cards existentes*/
        display: grid;
        grid-template-areas:
            'cards cards cards cards cards cards';
        grid-area: cards;
        background-color: #f5f5f5;
        box-shadow: 5px 5px 5px #ccc;
    }

    .dashboard-titulo {
        font-size: 25px;
        margin: 2%;
        font-weight: 600;
    }

    .dashboard-subtitulo {
        margin: 2%;
        margin-top: 5%;
        color: #A5A5A5;
        font-size: 15px;
    }

    .dashboard-overlay {
        margin-top: 5%;
        margin-bottom: 10%;
    }

    #caixa_btn_whats {
        margin: 5px 0px 0px 45px;
        display: block;
    }

    #mensagem_conecxao {
        color: green;
        display: block;
        margin: 0px auto
    }

    #cabecalho_tabela_pendentes {
        background-color: #CCC;
        font-size: 16px;
        font-weight: bold;
    }

    .linha_1 {
        background-color: #f2f5fb
    }

    .linha_0 {
        background-color: #FFF
    }

    #btn_abrir_at_pendente {
        margin: 5px;
        padding: 7px;
        background-color: #19b1bd;
        color: #FFF;
    }

        #btn_abrir_at_pendente:hover {
            background-color: #6f9bd4;
            border: 0px;
        }

        #btn_abrir_at_pendente:focus {
            background-color: #6f9bd4;
            border: 0px;
        }

    .text_at_pendente {
        padding: 7px 0px;
        display: table;
        margin: 0px auto;
    }
    .conversa_funil {
        float: right;
        cursor: pointer;
        color: #878787;
        font-size: 1.2rem;
    }
    .caixa_acoes{
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
