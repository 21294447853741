<template>
    <!--<div class="cartao-dashboard" v-on:click="$emit('click')" >-->
    <div class="cartao-dashboard"
         @click="$emit('modalAllAtendimento', permiteClick, origem, link)">
        <div class="conteudo-container">
            <p id="cartao-titulo">{{titulo}}</p>
            <span id="cartao-subtitulo">{{subtitulo}}</span><br />
        </div>
        <!-- <router-link v-if="link"
                     to="/relatorios"
                     style="font-size:11px;">Consultar todos </router-link> -->
    </div>
</template>

<script>
    export default {
        name: 'CartaoDashboard',
        props: {
            classe: String,
            titulo: String,
            subtitulo: String,
            permiteClick: Boolean,
            link: Boolean,
            origem: String
        },
    }
</script>

<style scoped>
    .cartao-dashboard {
        padding: 20px 0 10px;
        height: 100%;
    }

    .cartao-dinamico:hover {
        background-color: #f5f5f5;
    }
    .conteudo-container {
        border-right: 1px solid #ccc;
    }

    #cartao-titulo {
        text-align: center;
        font-weight: bolder;
        margin: 10px 0 2px;
        font-size: 20px;
    }

    #cartao-subtitulo {
        font-size: small;
        color: #808080;
    }

    .btn_dash_clicavel {
        cursor:pointer;
    }
    .btn_dash_clicavel:hover {
        text-decoration:underline;
    }
</style>
